import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggler"
export default class extends Controller {
  toggle() {
    let el = document.getElementsByClassName("hero-section-buttons");
    let firstElement = el[0];
    let allButtons = firstElement.children;

    if (!this.element.classList.contains("active-hero-button")) {
      this.element.classList.add("active-hero-button");
    }
    for (let index = 0; index < allButtons.length; index++) {
      if (allButtons[index] != this.element) {
        if (allButtons[index].classList.contains("active-hero-button")) {
          allButtons[index].classList.remove("active-hero-button");
        }
      }
    }
  }
}
