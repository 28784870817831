import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="swap"
export default class extends Controller {
  switchOnFeature() {
    let featured = document.getElementById("highlightFeatured");
    let upcoming = document.getElementById("highlightUpcoming");
    let blogs = document.getElementById("highlightBlogs");
    let calendar = document.getElementById("highlightCalendar");

    if (!featured.classList.contains("highlight-active")) {
      featured.classList.add("highlight-active");
    }
    upcoming.classList.remove("highlight-active");
    upcoming.classList.add("highlight-inactive");

    blogs.classList.remove("highlight-active");
    blogs.classList.add("highlight-inactive");

    calendar.classList.remove("highlight-active");
    calendar.classList.add("highlight-inactive");
  }

  switchOnUpcoming() {
    let featured = document.getElementById("highlightFeatured");
    let upcoming = document.getElementById("highlightUpcoming");
    let blogs = document.getElementById("highlightBlogs");
    let calendar = document.getElementById("highlightCalendar");

    if (!upcoming.classList.contains("highlight-active")) {
      upcoming.classList.remove("highlight-inactive");
      upcoming.classList.add("highlight-active");
    }
    featured.classList.remove("highlight-active");
    featured.classList.add("highlight-inactive");

    blogs.classList.remove("highlight-active");
    blogs.classList.add("highlight-inactive");

    calendar.classList.remove("highlight-active");
    calendar.classList.add("highlight-inactive");
  }

  switchOnBlogs() {
    let featured = document.getElementById("highlightFeatured");
    let upcoming = document.getElementById("highlightUpcoming");
    let blogs = document.getElementById("highlightBlogs");
    let calendar = document.getElementById("highlightCalendar");

    if (!blogs.classList.contains("highlight-active")) {
      blogs.classList.remove("highlight-inactive");
      blogs.classList.add("highlight-active");
    }
    featured.classList.remove("highlight-active");
    featured.classList.add("highlight-inactive");

    upcoming.classList.remove("highlight-active");
    upcoming.classList.add("highlight-inactive");

    calendar.classList.remove("highlight-active");
    calendar.classList.add("highlight-inactive");
  }

  switchOnCalendar() {
    let featured = document.getElementById("highlightFeatured");
    let upcoming = document.getElementById("highlightUpcoming");
    let blogs = document.getElementById("highlightBlogs");
    let calendar = document.getElementById("highlightCalendar");

    if (!calendar.classList.contains("highlight-active")) {
      calendar.classList.remove("highlight-inactive");
      calendar.classList.add("highlight-active");
    }
    featured.classList.remove("highlight-active");
    featured.classList.add("highlight-inactive");

    upcoming.classList.remove("highlight-active");
    upcoming.classList.add("highlight-inactive");

    blogs.classList.remove("highlight-active");
    blogs.classList.add("highlight-inactive");
  }
}
